$white: #fff;
$off-white: #f9f9f9;
$cyan: #00a1e6;
$lemongreen: hsl(72, 100%, 50%);
$red: hsla(0, 100%, 64%, 1);

$navbar-height: 5rem;
$footer-min-height: 455px;

@mixin light-shadow {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

@mixin sm-light-shadow {
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.11), 0 3px 7px 0 rgba(0, 0, 0, 0.08);
}