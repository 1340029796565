/* You can add global styles to this file, and also import other style files */
@import 'variables';

$navbar-item-color: $white;
$tabs-link-color: rgba(54, 54, 54, 0.3);
$tabs-link-border-bottom-color: rgba(54, 54, 54, 0.3);
$tabs-link-hover-color: #B5B5B5;
$tabs-link-hover-border-bottom-color: #B5B5B5;
$tabs-link-active-color: $cyan;
$tabs-link-active-border-bottom-color: $cyan;
$input-focus-border-color: $cyan;
$primary: $cyan;
$success: $lemongreen;
$navbar-item-hover-background-color: rgba(255, 255, 255, 0.2);
$navbar-item-hover-color: $white;

@import 'bulma/bulma';

body {
  background-color: $off-white;
  overflow: auto;
}

html, body {
  height: 100%;
}

label {
  font-family: 'Jura', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: #ababab;
  letter-spacing: 1px;
}

.has-icon-left {
  margin-left: 7px;
}
